import React, { Component } from 'react';
import weatherpiProto from './images/weatherpiProto.png';
import piData from './images/raspberrydata.mp4';




export class ProgressLog extends Component {
    static displayName = ProgressLog.name;

    render() {
        return (









            <div className="logbox">


                <div className="roundtextbox">
                    <h1>13/6-23 Status:</h1>
                    <p>Finally got multi plot ReactVis chart working... had made a stupid mistake of using { } instead of [] when building array of X Y pairs in my async fetch. </p>
                    <p> Took me embarrasingly long to realize my error was not in my static or render as the network console showed a logically dataset in Edge</p>
                    <p>Chart still looks awfall visually, but building blocks are getting there...</p>
                    <p>Sensor finally arrived from adafruit, but have to fix car first in order to get home...</p>


                </div>




                <div className="roundtextbox">
                    <h1>31/5-23 Status:</h1>
                    <p>Back from vacation, caught up on backloaded work</p>                     
                    <p>Still waiting for sensors...</p>
                  

                </div>

                <div className="roundtextbox">
                    <h1>12/5-23 Status:</h1>
                    <p>ChartJS...React-chartjs-2...Rechart....React-vis....VIctory.. CanvasJS...</p>
                    <p>Did not expect getting stuck on the chart...</p>
                    <ul>
                        <li>React-chartjs-2</li>
                        <ul>
                            <li>Wasted a "week" trying to get react-chartjs-2 to work.</li>
                            <li>Stuck on react-dom error, and npm package folder disappearing after builds, and an error referancing chartjs.tsx</li>
                            <li>Examples on webpage all written in .tsx files made me more confused ... do not like giving up, but this was poor time management</li>
                        </ul>


                        <li>CanvasJS</li>
                        <ul>
                            <li>Commercial licensing...30day free trial, but wanted to test something without npm due to my issue with react-chartjs-2</li>
                            <li>Very straight forward compared to react-chartjs-2, took 30 minutes to get a chart with Pi data...</li>
                            


                            
                        </ul>

                        <li>React-Vis</li>
                        <ul>
                            <li>Good looking charts</li>
                            <li>Got Chart working. Going on vacation, will have to get React-Vis working good when i get back...</li>

                        </ul>


                    </ul>





                </div>

                <div className="roundtextbox">
                    <h1>24/4-23 Status:</h1>
                    <p>Finally som data on website!</p>

                    <ul>
                        <li>To do:
                            <ul>
                                <li>Get graphical data representation running</li>
                                <li>Expand API options</li>
                                <li>Make the rest of the sensors</li>
                                <li>Dual ebay description fooled me... got a BMP280 instead of a BME280..no humidity.. reordered</li>
                                <li>Calibrate sensors</li>
                                <li>Start using stage sub domain for testing, keep breaking the online site</li>
                                <li>... ... ... ...</li>
                                <li>Build admin site for publishing, getting numbers on the screen was first priority </li>
                                <li>Build login page for selected users</li>
                                <li>Actually learn how i did all this...</li>
                                


                                </ul>
                                </li>


                    </ul>

                    <ul>
                        <li>Done:</li>
                        <ul>

                            <li>Raspberry collects data via I2C and SPI via C#</li>
                            <li>Raspberry posts to mySQL</li>
                            <li>C# Web API retrieves data and serves with CORS</li>
                            <li>React App can fetch data from API</li>
                            <li>React App can broadcast the temperature and barometric pressure by my laptop to the world</li>
                            <li>api.dhncode.com/hellvikdata/getlast/n  --Gets last n samples, max 4464</li>

                        </ul>
                    </ul>

                </div>




                <div className="roundtextbox">
                    <h1>19/4-23 Status:</h1>
                    <p>Web API</p>
                    
                    <ul>
                        <li>To do:
                            <ul>
                                <li>Connect React to backend Web API</li>
                                <li>Resolve CORS issues....</li>

                                
                               
                            </ul></li>


                    </ul>

                    <ul>
                    <li>Done:</li>
                    <ul>
                        <li>Raspberry posts to mySQL database</li>
                        <li>Tested entity framework web API controller, i can get data from my "weather station", to online mySQL, and back out ...</li>
                        </ul>
                    </ul>



                </div>


                <div className="roundtextbox">
                    <h1>11/4-23 Status:</h1>
                    <p>Next step add mySQL connection...</p>

                    <ul>
                        <li>Raspberry SSH ready:  </li>
                          
                        <div className="roundedImage">
                            <video position="absolute" left="0" bottom="0" width="100%" object-fit="contain" loop muted autoPlay  >
                                <source src={piData} type="video/mp4" />

                            </video>
                        </div>

                    </ul>




                </div>



                <div className="roundtextbox">
                    <h1>10/4-23 Status:</h1>
                    <p>Next step add mySQL connection...</p>

                    <ul>
                        <li>Raspberry Pi ready:
                            <ul>
                                <li>Permaproto hat soldered</li>
                                <li>MCP3008 ADC - Tested over SPI with potentiometer </li>
                                <li>BME280 and TSL2561 added to I2C</li>
                                <li>Simple C# console app written to read I2C and SPI over SSH with status led to GPIO</li>
                            </ul></li>
                       <div className = "roundedImage">
                        <img src={weatherpiProto} alt="weatherpiProto" width="300" height="400" />
                        </div>

                    </ul>

                    <ul>
                        <li>Air quality - TGS2600 - still not arrived, most likely useless, we have great air...</li>
                        <li>Rain - DIY - Cups, push button? </li>
                        <li>Wind_speed - waiting for reed switches, will connect to GPIO and read push per 10sec, 3d print parts?</li>
                        <li>Wind_dir - waiting for reed switches, will connect to MCP3008 with 8 diffent resistors for N,S,E,W,NE,NW,SE,SW 3d print parts?</li>

                    </ul>



                </div>

                <div className="roundtextbox">
                <h1>Status: Parts ordered:</h1>
                <p>Raspberry Pi flashed, ssh enabled</p>
                <p>29/3-23 Waiting for sensors </p>
                    <ul>
                            <li>Temp, pressure, humidity - BME280 - Ordered</li>
                            <li>Air quality - TGS2600 - Ordered</li>
                            <li>Sunlight - TSL2561 - Ordered</li>
                            <li>Temp - DS18B20 - Ordered</li>
                            <li>Rain - DIY - Cups, push button? </li>
                            <li>Wind_speed - DIY Ordered reed switch, 3d print parts?</li>
                            <li>Wind_dir - DIY Ordered reed switch, 3d print parts?</li>

                    </ul>

                </div>





                <div className="roundtextbox">
                <h2>Initial planning:</h2>

               
                    <ul>
                    <li>4/3-23, plain HTML, CSS, bootstrap at dhncode.dev...</li>
                    <li>10/3-23, migrated to dhncode.com with new host provider supporting backend infrastructure. Updated style and layout</li>
                    <li>20/3-23, moved over to React SPA.</li>
                    <li>....practice web app in progress!
                        <ul>
                            <li> need GDPR friendly data for database... borrow or self produce...
                                <ul>
                                    <li>Useless lotteri prediction app based on pet movements in key matrix as a pun to a colleagues lottori system or...</li>
                                    <li>Weatherstation... boring but safe, usefull to myself and 3 neighboers, but will most likely avoid prosecution...
                                        <ul>
                                            <li>Raspberry pi... 2 in stock, scrap kali linux keep SegaMega Drive</li>
                                            <li>Need sensor kit..<ul>
                                                <li>Temp, pressure, humidity - BME280 - Ordered</li>
                                                <li>Air quality - TGS2600 - Ordered</li>
                                                <li>Sunlight - TSL2561 - Ordered</li>
                                                <li>Temp - DS18B20 - Ordered</li>
                                                <li>Rain - DIY - Cups, push button? </li>
                                                <li>Wind_speed - DIY Ordered reed switch, 3d print parts?</li>
                                                <li>Wind_dir - DIY Ordered reed switch, 3d print parts?</li>

                                            </ul></li>
                                            <li>Plan A c# for GPIO. Plan B Python detour....</li>
                                            <li>c# web api on raspberry</li>
                                            <li>c# server backend</li>
                                            <li>React frontend</li>
                                            <li>Bonus: Could still add lotteri prediction easter egg for colleague... everything sounds scientific when associated with barometric pressure</li>
                                         </ul>

                                       </li>
                                   </ul>
                                </li>
                              </ul>
                            </li>
                        </ul>
                </div> 
            </div>

        );
    }
}