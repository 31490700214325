import React, { Component } from 'react';


export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
      this.state = { PiData: [], loading: true, currentCount: 1 };
      this.incrementCounter = this.incrementCounter.bind(this);
      this.decrementCounter = this.decrementCounter.bind(this);
      
    }

    incrementCounter() {
        if (this.state.currentCount < 30) {
            this.setState({
                currentCount: this.state.currentCount + 1
            });
        }
        else {
            this.setState({
                currentCount: this.state.currentCount + 0
            });
        }
    }

    decrementCounter() {
        if (this.state.currentCount > 1) {
            this.setState({
                currentCount: this.state.currentCount - 1
            });
        }
        else {
            this.setState({
                currentCount: this.state.currentCount + 0
            });

        }

    }
    

  componentDidMount() {
    this.getPiData();
    }


    componentDidUpdate(_prevProps, prevState) {
        if (prevState.currentCount !== this.state.currentCount) {
            this.getPiData();
        }
    }


  static renderPiDataTable(PiData) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Time</th>
            <th>(C)</th>
            <th>(hPa)</th>
            <th>%h20</th>
            <th>(Lux)</th>
          </tr>
        </thead>
        <tbody>
          {PiData.map(entry =>
            <tr key={entry.dbIndex}>
              <td>{entry.timeStamp}</td>
              <td>{entry.temp1}</td>
              <td>{entry.pressure}</td>
              <td>{entry.humidity}</td>
              <td>{entry.sunlight}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
    }





  render() {

      let contents = this.state.loading
      ? <p id="loading"><em>....Crunching the numbers....</em></p>
      : FetchData.renderPiDataTable(this.state.PiData);

    return (
        <div>
        
            <h3 id="tabelLabel" >Table Data from API</h3>
            <h3 id="tabelLabel">... to be replaced...</h3>
            <div className="tableincrementBox">
            <p aria-live="polite"># Data: <strong>{this.state.currentCount}</strong></p>
            <button className="btn-primary" onClick={this.incrementCounter}>More</button>
                <button className="btn-primary" onClick={this.decrementCounter}>Less</button>
            </div>
        {contents}
      </div>
    );
  }

  async getPiData() {
      console.log("wait one sec")
      await new Promise(r => setTimeout(r, 500));
      const response = await fetch(`https://api.dhncode.com/hellvikdata/getlast/${this.state.currentCount}`);
      
      const Data = await response.json();
      console.log(Data);
      
    // use if one const piArray = [Data];
    // use if one console.log(piArray);

      
      
    this.setState({ PiData: Data, loading: false });
  }
}


