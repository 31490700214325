import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import topVideo from './images/topVideo.mp4';



import {DisplayData} from './DisplayData';


export class Home extends Component {
  static displayName = Home.name;
  
  render() {
      return (
          
          <div className="roundtextboxFlex">
              <h1>Weather station</h1>
              <h1>..coming soon..</h1>

              <p id="mainp">I am not a weather enthusiast, but need data to do some coding. <br/> My own weather data secures a copyright and GDPR safe data set to play with.</p>

            
              <div className="chartbox">
                 <DisplayData />
              </div>




              <div className="videobox">

                  <Link to="/ProgressLog">
                      <video position="absolute" left="0" bottom="0" width="100%" object-fit="contain" loop muted autoPlay  >
                      <source src={topVideo}   type="video/mp4" />

                      </video>
                      
                  </Link>
                  

              </div >          
              <h4>Click printer to view progress</h4>







 
          </div>
          
      );
  }
}
