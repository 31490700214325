import { Contact } from "./components/Contact";
import { Privacy } from "./components/Privacy";
import { Counter } from "./components/Counter";
import { PastProjects } from "./components/PastProjects";
import { ProgressLog } from "./components/ProgressLog";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";


const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
          path: '/Contact',
          element: <Contact />
  },
  {
          path: 'Privacy',
          element: <Privacy />
    },
    {
        path: 'PastProjects',
        element: <PastProjects />
    },
    {
        path: 'ProgressLog',
        element: <ProgressLog />
    },
    


];

export default AppRoutes;
