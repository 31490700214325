import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from './images/logo/DHNCODELong.png';


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 bg-dark" container dark>
                <NavbarBrand tag={Link} to="/"> <img src={logo} alt="logo" width="150" height="20" />' </NavbarBrand>

         <div className="dropdown">
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            
            <ul className="navbar-nav flex-grow">
              <NavItem>
                                <NavLink tag={Link} className="text-light" to="/" onClick={this.toggleNavbar}>Home</NavLink>
              </NavItem>
              <NavItem>
                                <NavLink tag={Link} className="text-light" to="/ProgressLog" onClick={this.toggleNavbar}>ProgressLog</NavLink>
              </NavItem>
              <NavItem>
                                <NavLink tag={Link} className="text-light" to="/PastProjects" onClick={this.toggleNavbar}>PastProjects</NavLink>
              </NavItem>
              <NavItem>
                                <NavLink tag={Link} className="text-light" to="/contact" onClick={this.toggleNavbar}>Contact</NavLink>
              </NavItem>
              <NavItem>
                                <NavLink tag={Link} className="text-light" to="/privacy" onClick={this.toggleNavbar}>Privacy</NavLink>
              </NavItem>
            </ul>
         
          </Collapse>
         </div>
        </Navbar>
      </header>
    );
  }
}
