import React, { Component } from 'react';

import './Footer.css';



export class Footer extends Component {




  render() {
    return (
        <div className="footerbox">

           <h1 className="footerh1">dhncode.com</h1>

        </div>
    );
  }
}
