import React, { Component } from 'react';


export class Contact extends Component {
    static displayName = Contact.name;

    render() {
        return (
            
            <div className="roundtextbox">
                <h1>.... site in progress</h1>
               
                <p>BigBoss@dhncode.dev</p>
                </div>
            
        );
    }
}
