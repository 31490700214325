import React, { Component } from 'react';
import qrDateCalc from './images/qrcodeDateCalc.png';

export class PastProjects extends Component {
    static displayName = PastProjects.name;

    render() {
        return (

            <div className="roundtextbox">
                <h1>...will dwell on the past when done with the present...</h1>
                
                <p>DateCalc App: This app was primarily for myself, uploaded to play store to accomadate for a colleague so he did not have to allow usb debugging or allow "unknown" installers. Made for a userbase of two to save 3.6 calories a day... link below</p>

                <a href="https://play.google.com/store/apps/details?id=com.dhn.datecalc">
                    <img src={qrDateCalc} alt="qrCode" width="300" height="300" />
                </a>
            </div>

        );
    }
}