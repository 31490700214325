import React, { Component } from 'react';
import { timeFormatDefaultLocale } from 'd3-time-format';

import {
    XYPlot,
    LineSeries,
    VerticalGridLines,
    HorizontalGridLines,
    XAxis,
    YAxis
} from 'react-vis';


timeFormatDefaultLocale({
    "dateTime": "%a %e %b %X %Y",
    "date": "%d/%m/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
});





export class DisplayData extends Component {
  static displayName = DisplayData.name;

  constructor(props) {
    super(props);
      this.state = { PiData: [], loading: true, currentCount: 288
      };
      this.incrementCounter = this.incrementCounter.bind(this);
      this.decrementCounter = this.decrementCounter.bind(this);
      
    }




    incrementCounter() {
        if (this.state.currentCount < 4319) {
            this.setState({
                currentCount: this.state.currentCount + 144
            });
        }
        else {
            this.setState({
                currentCount: this.state.currentCount + 0
            });
        }
    }

    decrementCounter() {
        if (this.state.currentCount > 150) {
            this.setState({
                currentCount: this.state.currentCount - 144
            });
        }
        else {
            this.setState({
                currentCount: this.state.currentCount + 0
            });

        }

    }




    componentDidMount() {
        this.getPiData();
    }


    componentDidUpdate(_prevProps, prevState) {
        if (prevState.currentCount !== this.state.currentCount) {
            this.getPiData();
        }
    }



    static renderPiDataChart(PiData) {

        //let PiDataTemp1 = PiData[0]; //temp1data lineStyle={{ stroke: "black" }, { fill: 'none' }}
        //let PiDataTemp2 = PiData[1]; //temp2data
        //let PiDataHumidity = PiData[2]; //humdata  lineStyle={{ stroke: "red" }, { fill: 'none' }}
        //let PiDataPressure = PiData[3]; //pressdata
        //let PiDataRain = PiData[4]; //raindata  lineStyle={{ stroke: "blue" }, { fill: 'none' }}
        //let PiDataSunlight = PiData[5]; //sundata
        //let PiDataWinddir = PiData[6]; //winddirdata
        //let PiDataWindspeed = PiData[7]; //windsdata

        //map data to lineseries

         return (
            <div className="chart">
                 <XYPlot xType="time" yDomain={[0, 30]} height={300} width={300}  >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                     <XAxis tickLabelAngle={-35} color="black"  />
                     <YAxis color="black" />
                     <LineSeries data={PiData[0]} lineStyle={{ stroke: "black" }, { fill: 'none' }} />
                     <LineSeries data={PiData[2]} lineStyle={{ stroke: "red" }, { fill: 'none' }} />
                     <LineSeries data={PiData[4]} lineStyle={{ stroke: "blue" }, { fill: 'none' }} />
                     <LineSeries data={PiData[5]} lineStyle={{ stroke: "green" }, { fill: 'none' }} />
                </XYPlot>
            </div>
        );
    }





    render() {

        let contents = this.state.loading
            ? <p id="loading"><em>....Crunching the numbers....</em></p>
            : DisplayData.renderPiDataChart(this.state.PiData);


        return (
            <div className="videobox">
                <button className="chartbtn" onClick={this.incrementCounter}>More Data</button>
                <button className="chartbtn" onClick={this.decrementCounter}>Less Data</button>

                {contents}


            </div>
            
 

        );
    }







    async getPiData() {


        const response = await fetch(`https://api.dhncode.com/hellvikdata/getlast/${this.state.currentCount}`);
        const Data = await response.json();
      


        var temp1data = []; 
        var temp2data = [];
        var humdata = [];
        var pressdata = [];
        var raindata = [];
        var sundata = [];
        var winddirdata = [];
        var windsdata = [];
        var chartdata = [ temp1data, temp2data, humdata, pressdata, raindata, sundata, winddirdata, windsdata ];

        function temp1chartdata() {

            for (var i = 0; i < Data.length; i++) {
                temp1data.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].temp1
                });
                temp2data.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].temp2
                });
                humdata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].humidity
                });
                pressdata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].pressure
                });
                raindata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].rain
                });
                sundata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].sunlight
                });
                winddirdata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].windDir
                });
                windsdata.push({
                    x: new Date(Data[i].timeStamp).getTime(),
                    y: Data[i].windSpeed
                });
            }
        }
        temp1chartdata();


        
        console.log(chartdata);

                // use if one const piArray = [Data];
                // use if one console.log(piArray);



        this.setState({PiData : chartdata,
            loading: false
        });

            }
 }