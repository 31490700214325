import React, { Component } from 'react';


export class Privacy extends Component {
    static displayName = Privacy.name;

    render() {
        return (

            <div className="roundtextbox">
                <h1>I collect no data... only my own...</h1>
                    <p>This site does not collect, manage or store any user data.</p>
                    <p>The DateCalc 30-90-360 App does not collect, manage or store any user data.</p>
                </div>
            
        );
    }
}
